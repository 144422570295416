import { put, takeLatest, cancel, cancelled, call, select, fork } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

import { halt } from '../../sagas';
import { watchSubmitTrimbleLogin } from '../Trimble/trimbleSagas';
import { watchSubmitSocialLogin } from '../SocialAccounts/socialAccountsSagas';
import { watchResendActivation } from '../Profile/profileSagas';
import { validateCorporateMail } from '../CorporateLogin/corporateSagas';
import { errorMessage, linkMessage } from '../CorporateLogin/constants';
import { getCorporateLoginURL } from '../CorporateLogin/utils';
import { Api } from '../Utils/api';
import { loginRedirect, logoutRedirect, getQueryParamByName } from '../Utils/helpers';
import { isLength, isEmail, validateData } from '../Utils/Validation';
import { validationRules as registerValidations, warningRules as registerWarnings } from '../Register/registerSagas';

function* submitLogin({ isTrial = false }) {
  const fieldErrorAction = isTrial ? 'TRIAL_SET_FIELD_ERRORS' : 'SUBMIT_LOGIN#ERROR';
  try {
    const loginData = yield select((state) => (isTrial ? state.trial.fields : state.login));
    const fieldErrors = yield validateData(loginData, loginValidations);
    if (fieldErrors) {
      yield put({ type: fieldErrorAction, fieldErrors });
      yield cancel();
    }

    const trialParams = {};
    if (isTrial) {
      const returnTo = getQueryParamByName('return_to');
      if (returnTo.includes('try.enscape3d.com')) {
        trialParams.isEnscapeTrial = true;
      } else {
        trialParams.isTrial = true;
      }
    }
    const { RequiredPasswordUpdate, ...otherData } = yield call(Api.fetch, '/account/login', {
      method: 'POST',
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password,
        ...trialParams,
      }),
      onUnauthorized: 'throw',
      on5xx: 'throw',
    });

    yield put({ type: 'SUBMIT_LOGIN#COMPLETE', data: otherData });

    const returnToAddr = getQueryParamByName('return_to');

    if (RequiredPasswordUpdate) {
      const returnTo = isTrial ? `${window.location.origin}/trial/success` : returnToAddr || window.HOME_PAGE;
      window.location = `/account/password-change?return_to=${encodeURIComponent(
        returnTo,
      )}&required_password_update=true`;
      yield cancel();
      // ToDo: bring it back after domain switch
      // yield put(
      //   navigate('PASSWORD_CHANGE', null, {
      //     query: {
      //       return_to: returnTo,
      //       required_password_update: true,
      //     },
      //   }),
      // );
    } else {
      if (isTrial) {
        const redirectAddress = new URL(returnToAddr || window.TRIAL_ADDR);
        redirectAddress.searchParams.append('isNewAccount', false);
        window.location = redirectAddress;
        return;
      }
      yield call(loginRedirect, null, getQueryParamByName('return_to'));
    }
  } catch (error) {
    if (error.message.indexOf('password is incorrect') > -1) {
      yield put({ type: fieldErrorAction, fieldErrors: { password: error.message } });
    } else if (error.message.indexOf('cannot find account') > -1) {
      yield put({ type: fieldErrorAction, fieldErrors: { email: error.message } });
    } else {
      yield put({ type: fieldErrorAction, fieldErrors: { password: error.message }, error });
    }
  }
}

export function* navigateLogin() {
  try {
    const shouldReauthenticate = getQueryParamByName('prompt') === 'login';
    const { uid } = yield select((state) => state.app);

    yield fork(watchLoginInputChange);
    yield fork(watchResendActivation);
    const prefillEmail = getQueryParamByName('email');
    if (prefillEmail) {
      yield put({ type: 'PREFILL_EMAIL', email: prefillEmail });
    }
    yield put({ type: 'LOGIN_RESET' });
    yield put({ type: 'RESEND_ACTIVATION_CODE_RESET' });

    // Watch for submitLogin if not logged in or legacy login response requested, as
    // user won't be redirected if legacy login flow in use, even if logged in.
    if (!uid || shouldReauthenticate) {
      yield fork(watchSubmitLogin);
      yield fork(watchSubmitTrimbleLogin);
      yield fork(watchSubmitSocialLogin);
      yield call(halt);
    } else {
      const returnTo = getQueryParamByName('return_to');
      if (returnTo) {
        yield call(loginRedirect, null, returnTo);
      } else {
        yield put(navigate('ACCOUNT_EDIT'));
      }
    }
  } finally {
    if (yield cancelled()) {
      const state = yield select((s) => s);
      const routingID = state.routing.id;
      const loginEmail = state.login.email;

      if (routingID === 'PASSWORD_RECOVER' && isEmail(loginEmail)) {
        yield put({ type: 'RECOVER_PASSWORD_INPUT_CHANGE', data: { email: loginEmail } });
      }

      yield put({ type: 'LOGIN_RESET' });
    }
  }
}

export function* watchLoginInputChange() {
  yield takeLatest('LOGIN_INPUT_CHANGE', loginInputChange);
}

export function* loginInputChange() {
  const loginData = yield select((state) => state.login);
  const fieldWarnings = yield call(() => validateData(loginData, warningRules));

  yield put({ type: 'LOGIN_INPUT_CHANGE#WARN', fieldWarnings });
}

export function* navigateLogout() {
  const form = new FormData();
  const userAgent = getQueryParamByName('ua');
  if (userAgent) {
    form.append('ua', userAgent);
  }

  yield fetch(`${window.SSO_GATEWAY}/account/logout`, {
    method: 'POST',
    credentials: 'include',
    body: form,
  });
  yield call(logoutRedirect);
}

export function* watchSubmitLogin() {
  yield takeLatest('SUBMIT_LOGIN', submitLogin);
}

/* Validation rules */
const loginValidations = {
  // TODO (edimov) set proper email validation when ready to drop username login
  email: [
    {
      f: (v) => isLength(v),
      message: 'Please enter your username or email address.',
    },
    {
      f: validateCorporateMail,
      message: {
        message: errorMessage,
        link: {
          text: linkMessage,
          href: getCorporateLoginURL(),
        },
      },
    },
  ],
  password: [...registerValidations.password],
};

const warningRules = {
  password: [...registerWarnings.password],
};
